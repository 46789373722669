table {
    
    /*border-collapse: collapse;*/
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
    


    box-shadow: var(--feature-shadow);
    -webkit-box-shadow: var(--feature-shadow);
    -moz-box-shadow: var(--feature-shadow);
  }
  
  table caption {
    font-size: 1.5em;
    margin: .5em 0 .75em;
  }
  
  table tr {
    background-color: var(--color-white);
    border: var(--separator-line);
    padding: .35em;
  }
  
  table th,
  table td {
    padding: .625em;
    text-align: center;
    border: var(--separator-line);
  }
  
  table th {
    font-size: .85em;
    letter-spacing: .1em;
    text-transform: uppercase;
    
  }
  
  @media screen and (max-width: 600px) {
    table {
      border: 0;

    }
  
    table caption {
      font-size: 1.3em;
    }
    
    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    
    table tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: .625em;
    }
    
    table td {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: .8em;
      text-align: right;
    }
    
    table td::before {
      /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }
    
    table td:last-child {
      border-bottom: 0;
    }
  }