
#experienceHome {
    padding-top:5rem ;
    padding-bottom:5rem ;

}

.experienceHome__container {
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap:2rem;
    
}

.experienceHome__container  > div {
  background: var(--color-bg-white);
  /*padding:2.4rem 5rem;*/
  
 /* border:  var(--separator-line);*/
  /*border-radius: 5%;*/
  /*transition:var(--transition);*/
 
 /* box-shadow: var(--feature-shadow);
-webkit-box-shadow: var(--feature-shadow);
-moz-box-shadow: var(--feature-shadow);*/
}


.experienceHome__container  > div:hover {
    background: transparent;
   /* border-color:var(--color-primary-variant);*/
    
    cursor:default;
}

.experienceHome__container > div h3 {
  text-align:center;
  margin-bottom: 2rem;
  color:var(--color-primary);
}


.experienceHome__image__container {
    grid-row: span 2;
    display: flex;
    justify-content: center;
    align-items: center;
   
  
}


.experienceHome__image__container img {
  /*height:25rem;*/
  width:27rem;
 aspect-ratio: 35 / 25;
}


.experienceHome__backend {
    display: flex;
    
    gap:0px;
    /*scale:0.8;*/
   

}



.small-image-home {
 flex:1;
 position: relative;
 /*top:15%;*/

}

.large-desc-home {
    flex:5; 
    font-size: 1.3rem;
    
}

.small-image-home img {
    /*height:max-content;*/
    height:2.7rem;
    width:2.7rem;
   
}

.experienceHome__content {
   display:grid;
   grid-template-columns: 1fr 1fr;
   row-gap:2rem;
   
}

.experienceHome__details {
    display:flex;
    gap:1rem;

}

.experienceHome__details-icon {
    margin-top: 6px;
    color:var(--color-primary);

}


.btn-pos {
   margin-top: 5%;
}

.shifting-marginTop {

margin-top:-12rem;
}

.shifting-marginBottom {

    margin-bottom:-10rem;
}


/*============== MEDIA QUERIES (KINDA LARGE DEVICES) ======================= */

@media screen and (max-width:1500px) {
    .experienceHome__container {
        grid-template-columns:1fr 1fr 1fr;

    }



    .shifting-marginTop {

        margin-top:-15rem;
        }
        
        .shifting-marginBottom {
        
            margin-bottom:-15rem;
        }
}


/*============== MEDIA QUERIES (1400px - 1300px) ======================= */

@media screen and (max-width:1600px) {

.experienceHome__backend {
    display: flex;
   
    gap:15px;
    /*scale:0.8;*/
  

}


.large-desc-home {
  
    font-size: 1.2rem;
}


.experienceHome__image__container img {
    /*height:25rem;*/
    width:23rem;
   aspect-ratio: 35 / 25;
  }
  

}


/*============== MEDIA QUERIES (1300px - 1024px) ======================= */

@media screen and (max-width:1480px) {

    .experienceHome__backend {
        display: flex;
       
        gap:15px;
        /*scale:0.8;*/
      
    
    }
    
    
    .large-desc-home {
      
        font-size: 1rem;
    }
    
    }




/*============== MEDIA QUERIES (1300px - 1024px) ======================= */

@media screen and (max-width:1300px) {

    .experienceHome__backend {
        display: flex;
       
        gap:15px;
        /*scale:0.8;*/
      
    
    }
    
    
    .large-desc-home {
      
        font-size: 0.9rem;
    }
    
    }



    /*============== MEDIA QUERIES (1200px - 1024px) ======================= */

@media screen and (max-width:1200px) {

    .experienceHome__backend {
        display: flex;
       
        gap:15px;
        /*scale:0.8;*/
      
    
    }
    
    
    .large-desc-home {
      
        font-size: 1rem;
    }
    
    }

       /*============== MEDIA QUERIES (1200px - 1024px) ======================= */

@media screen and (max-width:1100px) {

    .experienceHome__backend {
        display: flex;
       
        gap:15px;
        /*scale:0.8;*/
      
    
    }
    
    
   
    
    }



/*============== MEDIA QUERIES (MEDIUM DEVICES) ======================= */

@media screen and (max-width:1024px) {
  /* #experienceHome{
    display:none;
   }*/


   .large-desc-home {
      
    font-size: 1rem;
}
   
    .experienceHome__container {
        grid-template-columns: 1fr;
    }

    .experienceHome__container > div {
        width:80%;
        padding:2rem;
        margin:0 auto;
    }

    .experienceHome__content {
        padding: 1rem;
    }


    .shifting-marginTop {

        margin-top:-20rem;
        }
        
        .shifting-marginBottom {
        
            margin-bottom:-20rem;
        }
}


/*============== MEDIA QUERIES (SMALL DEVICES) ======================= */

@media screen and (max-width:600px) {
    .experienceHome__container {
        
        gap: 1rem;

    }

    .experienceHome__container > div {
        width:100%;
        padding:2rem 1rem;

    }



    .shifting-marginTop {

        margin-top:-25rem;
        }
        
        .shifting-marginBottom {
        
            margin-bottom:-25rem;
        }




}

 




