 #featureshome {
    margin-top: 2.5rem;
    background-color: var(--color-light-bg) !important;
 }




.featureshome__container {
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap:2rem;
    margin-top: 4rem !important;   
   
}

.featureshome__container  > div {
  background: var(--color-white);
  padding:2.4rem 5rem;
  
  border:  var(--separator-line);
  transition:var(--transition);
 
  box-shadow: var(--feature-shadow);
-webkit-box-shadow: var(--feature-shadow);
-moz-box-shadow: var(--feature-shadow);
}


.featureshome__container  > div:hover {
    /*background: transparent;*/
    border-color:var(--color-primary-variant);
    
    cursor:default;
}

.featureshome__container > div h3 {
  text-align:center;
  margin-bottom: 2rem;
  color:var(--color-primary);
}


.featureshome__content {
   display:grid;
   grid-template-columns: 1fr 1fr;
   row-gap:2rem;
}

.featureshome__details {
    display:flex;
    gap:1rem;

}

.featureshome__details-icon {
    margin-top: 6px;
    color:var(--color-primary);

}


.btn-pos {
   margin-top: 5%;
}

.iconContainer{
    color:var(--color-primary-variant);
    font-size: 2rem;
    background-color: var(--color-white);
    /*box shape*/
    /*border: var(--separator-line);*/
    width: max-content;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
     /*box shape*/

     /*box position*/
     position: relative;
     top:10%;
     left:10%;
     z-index:0 !;
     /*box position*/
}


/*============== MEDIA QUERIES (KINDA LARGE DEVICES) ======================= */

@media screen and (max-width:1500px) {
    .featureshome__container {
        grid-template-columns: 1fr 1fr;
        margin-top: 1rem;
    }

    .iconContainer{
       margin: 0 auto;
       top:8%;
       left:0%;
    }
}


/*============== MEDIA QUERIES (MEDIUM DEVICES) ======================= */

@media screen and (max-width:1024px) {
    .featureshome__container {
        grid-template-columns: 1fr 1fr;

    }

    .featureshome__container > div {
        width:80%;
        padding:2rem;
        margin:0 auto;
    }

    .featureshome__content {
        padding: 1rem;
    }
}


/*============== MEDIA QUERIES (SMALL DEVICES) ======================= */

@media screen and (max-width:600px) {
    .featureshome__container {
        
        gap: 1rem;
        grid-template-columns: 1fr;

    }

    .featureshome__container > div {
        width:100%;
        padding:2rem 1rem;

    }




}

 




