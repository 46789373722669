#payment {
    /* height:100vh;
     padding-top: 7rem;
     overflow:visible;*/
     /* padding-top: 5rem;*/
     background-color: #EBE9E9;
   
 }
 
 .payment__container {
     text-align: center;
     height:100%;
     position:relative;
     display: flex;
     justify-content: space-evenly;
     align-items: center;
     gap:5rem; 
 }
 
 .paymentExplainer{
     flex:1;
     padding:5%;

     display: flex;
     justify-content: flex-start;
     align-items: flex-start;
     flex-direction: column;
     gap:1rem;
     text-align: left;

    
     
 }

 .paymentExplainer h4 {
    font-size: 1.7rem;
     }


 .paymentExplainer p {
font-size: 1.5rem;
margin-top: 1rem;
 }
 
 .paymentCTA{
     flex:1.5;  /*INCREASE FLEX TO MOVE LOGOS MORE INWARD OR OUTWARD*/
     position: relative;
     
     display: flex;
     gap:1.5rem;
     
     
 }
 
 .CTAbox{
     background-color: var(--color-white);
     height: 480px;
     width: 400px;
     padding:5%;
     z-index: 100;
 }

 .Paymentboximg{
 border-radius: 5%;
 width:150px;
 aspect-ratio: 1 /1;
 }
 
 .btn-place{
     position: relative;
     top:40%;
     /*this positioning isnt exactly right but we'll  come back to it*/
 }
 
 .clip-path-bottom-right{
   background: #0767A880;
  
  clip-path: ellipse(100% 97% at 100% 97%);
   height: 100px;
   width:100px; 
 
   /*positioning of the quarter circle*/
   position:absolute;
   bottom:13%;
   right:-10%;
   z-index: 0;
   /*positioning END*/
 }
 
 
 
 .clip-path-bottom-left{
     background: var(--color-primary-variant) !important;
  
    clip-path: ellipse(100% 97% at 100% 97%);
     height: 100px;
     width:100px; 
 
 
 /*positioning of the quarter circle*/
 position:absolute;
 bottom:13%;
 left:-10%;
 z-index: 0;
   /*positioning END*/
   }
 
   
 
   .clip-path-top-left{
     background:var(--color-primary-variant);
   
    clip-path: ellipse(100% 97% at 0% 97%);
     height: 100px;
     width:100px; 
 
     /*positioning of the quarter circle*/
     position:absolute;
     top:-5%;
     left:-2%;
     z-index: -1;
 
     /*positioning END*/
   }
 
 /* ================ CTA ===============*/
 .cta {
     margin-top: 2.5rem;
     display:flex;
     gap:1rem; /*you need to add gap to your arsenal, you're still using margins inside flex and that sucks*/
     justify-content: center;
 
 }
 
 
 /* ================ payment SOCIALS ===============*/
 
 .payment__socials {
     display:flex;
     flex-direction:column;
     align-items: center;
     gap: 0.8rem;
 
     /*this is how we get those funky placements that accentuate a website*/
     position: absolute;
     left:0;
     bottom: 3rem;
 }
 
 /*notice how to do lines and shapes using "after pseudo element*/
 
 .payment__socials::after{ /*i believe after and before are usuially just boxes , but it is our sizing and rotating of them that will make them into different shapes*/
  content:'';
  width:1px;
  height:2rem;
  background: var(--color-primary);
 
 }
 
 
 /* ================ ME ===============*/
 
 .me {
     background: linear-gradient(var(--color-primary),55% ,transparent);
     width:24rem; 
     height:32rem;
     position:absolute;
 
     /* this is how you can center an absolute element*/
     /*normally i used to do top 50% left 50% but i guess it's the same thing*/
     left:calc(50% - 11rem);
     
 
     margin-top:4rem;
     border-radius:12rem 12rem 0 0;
     overflow:visible;
     padding:1rem 1.5rem 1.5rem 1.5rem;
 }
 
 /* ================ SCROLL DOWN ===============*/
 
 .scroll__down {
     position:absolute;
     right:-2.3rem;
     bottom:5rem;
     transform:rotate(90deg);
     font-weight:500;
     font-size:1.1rem;
 }
 
 
 /* ======================== MEDIA QUERIES FOR payment (KINDA LARGE DEVICES) ======================*/
 
 @media screen and (max-width:1500px) {
    
    .payment__container {
      
       gap:4rem;
        
    }
 
 
  }

   /* ======================== MEDIA QUERIES FOR HEADER CHANGING TO COLUMN DISPLAY ======================*/

 @media screen and (max-width:1300px) {

    .payment__container {
        flex-direction: column !important;
        align-items: center;
    }
    
 }
 
 /* ======================== MEDIA QUERIES FOR payment (MEDIUM DEVICES) ======================*/
 
 @media screen and (max-width:1024px) {
    
    .payment__container {
       flex-direction: column;
       gap:4rem;
     /*margin-top: 35rem !important;*/
        
    }
 
 
  }
  
  
  
  /* ======================== MEDIA QUERIES FOR payment (SMALL DEVICES) ======================*/
  
  @media screen and (max-width:600px) {
      
    
     
      .payment__socials, .scroll__down{
         display:none;
      }

      .payment__container {
       
     /* margin-top: 40rem !important;*/
         
     }
   
   }
 