
footer {
    /*background:var(--color-bg-nav);*/
    background-color: black;
    padding:3rem 0;
    text-align:center;
    font-size:0.9rem;
    /*margin-top: 7rem;*/
}

footer h2 {
    color:var(--color-white);
}

footer a {
    color:var(--color-white);
}

footer a:hover{
    color:var(--color-button);
}

.footer__logo {
    /*font-size:2rem ;
    font-weight: 500;*/
    margin-bottom: 2rem;
    display:inline-block;
    width:300px;
   /*aspect-ratio: 4.5 / 1;*/
}


.finalCta-container{
    padding-top: 5rem;

    text-align: center;
    width:70%;
    margin:0 auto;
    position:relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:0rem;
    z-index: 0;
  
}


.finalCta-Explainer{
    flex:1;
    padding:5%;
    text-align: left;
    margin-top: -8rem;
}


.finalCTA{
    flex:1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items:center;
   
   margin-top: -8rem;
}

.permalinks {
    display: flex;
    flex-wrap:wrap;
    justify-content: center;
    gap:5rem;
    margin:0 auto 3rem;
    
}

.socialsAndReviews {
    display: flex;
    flex-direction: column;
    gap: 2rem;
 
}

.socialsAndReviews img{
    width:200px;
    aspect-ratio: 2 / 1;
}


.socialsHolder {
    display: flex;
    gap:0.3rem;
    color:var(--color-white);
}

.circle-container{
  border-radius:50%;
  background-color:#1b0057;
 height:50px;
 width:50px;

 font-size: 1.7rem;
 
 display:flex;
 justify-content: center;
 align-items: center;
}

.reviewedBox{
    display: flex;
    gap:1rem;
    color:var(--color-white);
}


.clutch {
   display: flex; 
   flex-direction: column;
   flex:1
}

.clutchText {
    font-size:2.1rem;
    margin-top: -10px;
}


.starBox{
    display: flex; 
    flex-direction: column;
    
    flex:1;
    color:var(--color-white);
 }

 .starColor {
 color:red;
 font-size: 1.2rem;
 }

.footer__socials {
    display:flex;
    justify-content: center;
    gap:1rem;
    margin-bottom:4rem;

}

.footer__socials a {
    background: var(--color-bg);
    color:var(--color-white);
    padding: 0.8rem;
    border-radius: 0.7rem;
    display: flex;
    border:1px solid transparent;

}

.footer__socials a:hover{
    background:transparent;
    color:var(--color-bg);
    border-color:var(--color-bg);
}

.footer_divider {
    background-color:rgba(158, 217, 231, 0.274);
    height: 1px;
    width:100%;
}

.footer__copyright {
    margin-bottom: 0rem;
    font-size: 1.3rem;
    color:var(--color-white);

    display: flex;
    justify-content: space-between;

}






/* =================== MEDIA QUERIES (MEDIUM DEVICES) ================ */

@media screen and (max-width:1024px) {
    .permalinks{
        flex-direction:column;
        gap:1.5rem;

    }


    .socialsAndReviews {
        width:33%;
        margin: 0 auto;
        justify-content: center;
        align-items: center;
    }


    .footer__socials{
        margin-bottom: 2.6rem;
    }


    .finalCta-container{
        flex-direction: column;
      
    }
    

    .finalCTA{ 
       
       margin-top: 0rem;
       margin-bottom: 3rem;
    }
    
 }
 
 
 /* ================= MEDIA QUERIES (SMALL DEVICES) ==================== */
 @media screen and (max-width:600px) {
 
 
 }