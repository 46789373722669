.headerHome {
   /* height:100vh;
    padding-top: 7rem;
    overflow:visible;*/
    /* padding-top: 5rem;*/
    background-color: var(--color-header) !important;
   /* linear-gradient(
      
     var(--color-header) ,var(--color-white)
    ) */
   /*z-index: -100;*/
 
   margin-bottom: 0rem !important;
}

.headerHome__container-1{
    text-align: center;
    
    max-height:30rem;
    position:relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:2rem;
    z-index: 0;
    
   
}
 
.consentWarning{
    text-align: left;
    background-color: var(--color-white);
    padding:1rem;
    border-radius: 2rem;
}

.consentWarning h4{
  margin-bottom: 1rem;
  margin-top: 1rem;
  margin-left: 0.5rem;
}

.selectedConsent {
    display:flex;
    justify-content: center;
    align-items: center;
    gap:0.6rem;
    text-align: left;
}

.headerHomeExplainer{
    flex:1;
    padding:2%;
    padding-left: 17.8%;
    text-align: left;
    /*margin-top: -8rem;*/
    /*width:65%;*/
    scale:1.3
    

   
}


.header-btn-scale-down{
  scale:0.75;
  margin-left: -1.8rem;
}



.headerHomeCTA{
    flex:1.3;
    /*position: relative;*/
    background-color: red;
    max-width: 37%;
    max-height: 100%;
   
}

.CTAbox{
    background-color: var(--color-white);
    height: 100%;
    width: 100%;
    padding:10%;
    z-index: 100 ;
    text-align:left;
    border-radius: 1rem;
    border: var(--separator-line);
    position: relative;
   
   
}

.CTAHomeboximg{
   
  /*  width: 36rem;*/
  /*height:160%;*/
   /*aspect-ratio:35 / 25 ;*/

    /*POSITIONING OF IMAGE IN HEADER*/
  /* position:absolute;*/
 /* position:relative;
    top:-24%;
    right:0%;*/
   
     /*POSITIONING OF IMAGE IN HEADER*/


     max-height: 30rem;
     max-width: 100%;
    
}




.CTAbox h4{
    margin-top:1rem ;
    
  }

.CTAbox h5{
  margin-top:1.5rem ;
  margin-bottom:0.5rem ;
}

.CTAbox input{
    padding:5%;
    border:var(--separator-line);
    width: 90%;
    border-radius: 0.5rem;
    /*margin-bottom:3rem;*/
}

.btn-place{
    position: relative;
    top:0% !important;
    right:5%;
    /*this positioning isnt exactly right but we'll  come back to it*/
}

.sliderLabel {
    background-color:var(--color-primary);
    border-radius:2rem;
    width:max-content;
    padding-left:0.5rem;
    padding-right:0.5rem;
    color:white;
    position: relative;
    top:0.5rem;
   
}

.sliderEnds {
    margin-top: -1rem;
}

.clip-path-top-left-1{
    background:var(--color-primary-variant);
  
   clip-path: ellipse(100% 97% at 0% 97%);
    height: 100px;
    width:100px; 

    /*positioning of the quarter circle*/
    position:absolute;
    top:-5%;
    left:-2%;
    z-index: -1;

    /*positioning END*/
  }


.clip-path-bottom-left-1{
    background: var(--color-primary-variant) !important;

  
  clip-path: ellipse(100% 97% at 100% 97%);
    height: 100px;
    width:100px; 


/*positioning of the quarter circle*/
position:absolute;
/*top:85%;*/
top:25rem;
left:-4rem;
z-index: -1;
  /*positioning END*/
  }

.clip-path-bottom-right-1{
  background: #0767A880 !important;
  background-image:none !important;
 clip-path: ellipse(100% 97% at 100% 97%);
  height: 100px;
  width:100px; 

  /*positioning of the quarter circle*/
  position:absolute;
  top:25rem;
  /*left:60%;*/
  left:22rem;
  
  z-index: -1;
  /*positioning END*/
}




  

  

/* ================ CTA ===============*/
.cta {
    margin-top: 2.5rem;
    display:flex;
    gap:1rem; /*you need to add gap to your arsenal, you're still using margins inside flex and that sucks*/
    justify-content: center;

}


/* ================ HEADER SOCIALS ===============*/

.headerHome__socials {
    display:flex;
    flex-direction:column;
    align-items: center;
    gap: 0.8rem;

    /*this is how we get those funky placements that accentuate a website*/
    position: absolute;
    left:0;
    bottom: 3rem;
}

/*notice how to do lines and shapes using "after pseudo element*/

.headerHome__socials::after{ /*i believe after and before are usuially just boxes , but it is our sizing and rotating of them that will make them into different shapes*/
 content:'';
 width:1px;
 height:2rem;
 background: var(--color-primary);

}


/* ================ ME ===============*/

.me {
    background: linear-gradient(var(--color-primary),55% ,transparent);
    width:24rem; 
    height:32rem;
    position:absolute;

    /* this is how you can center an absolute element*/
    /*normally i used to do top 50% left 50% but i guess it's the same thing*/
    left:calc(50% - 11rem);
    

    margin-top:4rem;
    border-radius:12rem 12rem 0 0;
    overflow:visible;
    padding:1rem 1.5rem 1.5rem 1.5rem;
}

/* ================ SCROLL DOWN ===============*/

.scroll__down {
    position:absolute;
    right:-2.3rem;
    bottom:5rem;
    transform:rotate(90deg);
    font-weight:500;
    font-size:1.1rem;
}


@media screen and (max-width:1900px) {

    .clip-path-bottom-right-1{

        
       /* left:70%;*/
       left:22rem;
  
    }

    .headerHome__container-1{
        gap:2rem;
      
    }






  /*   .CTAHomeboximg{
     
       width: 47.2rem;
     height:125%;
      
   
      position:absolute;
       top:-24.2%;
      
        
   }*/


}

/* ======================== MEDIA QUERIES FOR HEADER (TO MANAGE THE PICTURE PLACED ABSOLUTELY) ======================*/

@media screen and (max-width:1870px) {

 /*   .CTAHomeboximg{
      
         width: 45.8rem;
       height:117%;
        
     
         
        position:absolute;
         top:-17%;
        
        
     }*/


}



/* ======================== MEDIA QUERIES FOR HEADER (TO MANAGE THE PICTURE PLACED ABSOLUTELY) ======================*/

@media screen and (max-width:1840px) {

   /* .CTAHomeboximg{
        
         width: 45.4rem;
       height:117%;
        
     
       
        position:absolute;
         top:-17%;
        
         
     } */


}




/* ======================== MEDIA QUERIES FOR HEADER (TO MANAGE THE PICTURE PLACED ABSOLUTELY) ======================*/

@media screen and (max-width:1800px) {

    /*.CTAHomeboximg{
       
         width: 44.2rem;
       height:117%;
        
     
        
        position:absolute;
         top:-17%;
        
         
     }*/


}

/* ======================== MEDIA QUERIES FOR HEADER (TO MANAGE THE PICTURE PLACED ABSOLUTELY) ======================*/

@media screen and (max-width:1770px) {

   /* .CTAHomeboximg{
       
         width: 43.5rem;
       height:117%;
        
     
        
        position:absolute;
         top:-17%;
        
          
     }*/


}

/* ======================== MEDIA QUERIES FOR HEADER (TO MANAGE THE PICTURE PLACED ABSOLUTELY) ======================*/

@media screen and (max-width:1735px) {

   /* .CTAHomeboximg{
       
         width: 42.8rem;
       height:117%;
        
     
        
        position:absolute;
         top:-17%;
        
        
     }*/


}



/* ======================== MEDIA QUERIES FOR HEADER (TO MANAGE THE PICTURE PLACED ABSOLUTELY) ======================*/

@media screen and (max-width:1700px) {

   /* .CTAHomeboximg{
      
         width: 42rem;
       height:117%;
        
     
        
        position:absolute;
         top:-17%;
        
         
     }*/


}


/* ======================== MEDIA QUERIES FOR HEADER (TO MANAGE THE PICTURE PLACED ABSOLUTELY) ======================*/

@media screen and (max-width:1680px) {

   /* .CTAHomeboximg{
      
         width: 41.6rem;
       height:117%;
        
     
        
        position:absolute;
         top:-17%;
        
         
     }*/


}



/* ======================== MEDIA QUERIES FOR HEADER (TO MANAGE THE PICTURE PLACED ABSOLUTELY) ======================*/

@media screen and (max-width:1650px) {

   /* .CTAHomeboximg{
       
         width: 40.2rem;
       height:117%;
        
     
        
        position:absolute;
         top:-17%;
        
          
     }*/


}


/* ======================== MEDIA QUERIES FOR HEADER (TO MANAGE THE PICTURE PLACED ABSOLUTELY) ======================*/

@media screen and (max-width:1600px) {

  /* .CTAHomeboximg{
       
         width: 39.7rem;
       height:117%;
        
     
        
        position:absolute;
         top:-17%;
        
         
     }*/


}


/* ======================== MEDIA QUERIES FOR HEADER (TO MANAGE THE PICTURE PLACED ABSOLUTELY) ======================*/

@media screen and (max-width:1560px) {

   /* .CTAHomeboximg{
      
         width: 38.2rem;
       height:117%;
        
     
        
        position:absolute;
         top:-17%;
        
        
     }*/


}


/* ======================== MEDIA QUERIES FOR HEADER (TO MANAGE THE PICTURE PLACED ABSOLUTELY) ======================*/

@media screen and (max-width:1520px) {

   /* .CTAHomeboximg{
        
         width: 37.3rem;
       height:117%;
        
     

        position:absolute;
         top:-17%;
        
 
     }*/


}


/* ======================== MEDIA QUERIES FOR HEADER (MEDIUM DEVICES) ======================*/

@media screen and (max-width:1500px) {


    /*.CTAHomeboximg{
      
         width: 35.3rem;
       height:116.2%;
        
       

        position:absolute;
         top:-16.3%;
        
 
     }*/

   
    .headerHome__container-1 {
        
        align-items: center;
       
    }


    .headerHomeExplainer{
       
        text-align:left;
       
     
    }

    .headerHomeExplainer{
 
      padding-left: 16.5%;
    
    }

    
    .clip-path-bottom-left-1{

    /* top:80%;*/
     top:25rem;
  
    }


    .clip-path-bottom-right-1{

       /* top:80%;*/
       /* left:80%;*/
       left:22rem;
        top:25rem;
  
    }

    .headerHome__container-1{
        gap:6rem;
      
    }

   
   

 }

  /* ======================== MEDIA QUERIES FOR HEADER ,MANAGING ABSOLUTELY PLACED PIC ======================*/

  @media screen and (max-width:1480px) {

    /*.CTAHomeboximg{
       
         width: 34.5rem;
       height:115%;
       
        
     
        
        position:absolute;
         top:-14.3%;
        
         
     }*/

   }




 /* ======================== MEDIA QUERIES FOR HEADER ,MANAGING ABSOLUTELY PLACED PIC ======================*/

 @media screen and (max-width:1460px) {

   /* .CTAHomeboximg{
       
         width: 33.6rem;
       height:115%;
       
        
     
        
        position:absolute;
         top:-14.3%;
        
         
     }*/

   }





    /* ======================== MEDIA QUERIES FOR HEADER ,MANAGING ABSOLUTELY PLACED PIC ======================*/

    @media screen and (max-width:1430px) {

       /* .CTAHomeboximg{
           
             width: 32.8rem;
           height:115%;
           
            
         
            
            position:absolute;
             top:-14.3%;
            
             
         }*/
    
       }



   /* ======================== MEDIA QUERIES FOR HEADER ,MANAGING ABSOLUTELY PLACED PIC ======================*/

   @media screen and (max-width:1400px) {

    /*.CTAHomeboximg{
       
         width: 31.6rem;
       height:115%;
       
        
     
        
        position:absolute;
         top:-14.3%;
        
         
     }*/

   }



   /* ======================== MEDIA QUERIES FOR HEADER ,MANAGING ABSOLUTELY PLACED PIC ======================*/

   @media screen and (max-width:1360px) {

   /* .CTAHomeboximg{
        
         width: 30.9rem;
       height:115%;
       
        
     
        
        position:absolute;
         top:-14.3%;
        
        
     }*/

   }


   /* ======================== MEDIA QUERIES FOR HEADER ,MANAGING ABSOLUTELY PLACED PIC ======================*/

   @media screen and (max-width:1320px) {

   /* .CTAHomeboximg{
        
         width: 30rem;
       height:115%;
      
           
        position:absolute;
         top:-14.3%;
        
     }*/

   }

  /* ======================== MEDIA QUERIES FOR HEADER CHANGING TO COLUMN DISPLAY ======================*/

 @media screen and (max-width:1300px) {

    .headerHome__container-1 {
        flex-direction: column !important;
        align-items: center;
    }


    .headerHomeExplainer{
       
      text-align:center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
   
  }

  .headerHomeExplainer{
 
    padding-left: 0%;
    padding-top: 5%;
   scale:1.1
  }
  
  .header-btn-scale-down{
    scale:1;
    margin-left: -0.8rem;
  }


    .CTAHomeboximg{
     
       display: none;
     
         /*POSITIONING OF IMAGE IN HEADER*/
       
          /*POSITIONING OF IMAGE IN HEADER*/
     }
    
 }
 

 /* ======================== MEDIA QUERIES FOR HEADER (MEDIUM DEVICES) ======================*/

@media screen and (max-width:1150px) {
   
    
    .clip-path-bottom-left-1{

     /*top:70%;*/
     top:25rem;
  
    }


    .clip-path-bottom-right-1{

        /*top:70%;*/
       top:25rem;
  
    }

    .headerHome__container-1 {
        flex-direction: column !important;
        align-items: center;
    }



 }
 


 /* ======================== MEDIA QUERIES FOR HEADER (KINDA SMALL DEVICES) ======================*/

@media screen and (max-width:1102px) {

   
    
    .clip-path-bottom-left-1{

     /*top:75%;*/
     top:25rem;
  
    }


    .clip-path-bottom-right-1{

        /*top:75%;*/
        top:25rem;
    }


 }
 

   /* ======================== MEDIA QUERIES FOR HEADER (KINDA SMALL DEVICES) ======================*/

@media screen and (max-width:1050px) {
   
    
    .clip-path-bottom-left-1{

     /*top:60%;*/
     top:25rem;
  
    }


    .clip-path-bottom-right-1{

        /*top:60%;*/
        top:25rem;
       
    }


 }


 @media screen and (max-width:1024px) {
      
    .headerHome__container-1 {
        /*padding-top: 8rem;*/ /*THIS IS WHERE U(DAGOGO) PUSHED THE HEADER DOWN FOR SMALLER SCREENS*/
    }

   

        
 
 }
 
 /* ======================== MEDIA QUERIES FOR HEADER (SMALL DEVICES) ======================*/
 
 @media screen and (max-width:600px) {
     
    .clip-path-bottom-left-1{

        /*top:50%;*/
        top:25rem;
        left:-1.5rem !important;
     
       }
   
   
       .clip-path-bottom-right-1{
   
          /* top:50%;*/
           top:25rem;
           left:19.5rem !important;
     
       }
    
     .headerHome__socials, .scroll__down{
        display:none;
     }
  
  }



