/* general css rules , before we go into individual pages -- senior devs do this, so get used to it*/
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&family=Poppins:wght@300;400;500;600&display=swap');
 @import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,500;8..144,600;8..144,700;8..144,800&display=swap');
 @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');


*{ /*please please please do this before you start any frontend project*/
    margin:0 ;
    padding:0;
    border:0;
    outline:0;
    box-sizing:border-box;
    list-style: none;
    text-decoration: none;

}
 /*this too ! you have to have a theming, if not you wont make if far as a front end dev*/
:root {
     --color-header:/*#272E41;*/#000000;
    --color-bg: /*#1f1f38*/#0767A830;
    --color-bg-nav: /*#1f1f38*//*#a1c8e4*/#0a001f;
    --color-bg-variant:#2f015f;
    --color-primary:/*#4db5ff*/#27374C;
    
    --color-primary-variant:#0767A8/*rgba(77,181,255,0.4)*/;
    --color-button:/*5561F3*/ #CC4436;
    --color-light-bg:#f3f8ff;
    --color-white:#fff;
    --color-light:rgba(255,255,255,0.6);

    --transition:all 400ms ease ;

    --container-width-lg: 85%;
    --container-width-md:85%;
    --container-width-sm:90%;

     --separator-line:0.1px solid #27374c1a;
     --feature-shadow:  5px 2px 10px -3px rgba(0,0,0,0.75);

   
   
}

html {
    scroll-behavior: smooth;
}

::webkit-scrollbar {
    display:none;
}


body{
    font-family: 'Lato', sans-serif;
    background: var(--color-white);
    color:var(--color-primary);
    line-height: 1.7;
   
   
}

/* ========================== GENERAL STYLES ========== */

.container {
    width:var(--container-width-lg);
    margin:0 auto;
   
}

h1,
h2,
h3,
h4,
h5,
h6 {
    
    font-family: 'Roboto Flex', sans-serif;
}

h1 {
    font-size:3rem;
    font-weight:700;
    margin-bottom:2.5rem;
}

h2 {
    font-size:2rem;
    font-weight:700;
    margin-bottom:2rem;

}

h3 {
    font-size:1.8rem;
    font-weight:600;
    margin-bottom:1rem;
    margin-top: 4rem;
}

h4,h5,h6 {
    font-weight:600;
}

p {
    margin-bottom:1rem; 
}


section {
    margin-top: 0rem;
    margin-bottom: 0rem;
}

section > h1,
section > h2,
section > h5 {

    text-align: center;
    color: var(--color-primary)
}




section > h2 {
    color:var(--color-primary);
    /*margin-bottom: 3rem;*/

}

.text-light {
    color: var(--color-white);
}

.text-accent {
    color: var(--color-button);
}

a { 
    color:var(--color-primary);
    transition:var(--transition);
}

.btn {
  width:200px ;  /* For text content this means that the content will not wrap at all even if it causes overflows  */
  height:60px ;
  display:inline-block; 
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  padding:0.75rem 1.2rem; 
  
  cursor:pointer;
  border-radius: 10px;
  /*border:1px solid var(--color-bg-variant);*/
  transition: var(--transition);
  text-align: center;
  margin-top: 3rem;
  font-size: 0.9rem;
}

.btn-f{
    width:350px;  /* For text content this means that the content will not wrap at all even if it causes overflows  */
    display:inline-block; 
    color: var(--color-white);
    padding:0.75rem 1.2rem; 
    cursor:pointer;
    background-color: #CDCDCD;
    border-radius: 0.4rem;
    /*border:1px solid var(--color-bg-variant);*/
    transition: var(--transition);
    text-align: center;
    margin-top: 3rem;
  }

.btn:hover {
    background:var(--color-primary);
    color:var(--color-white);
    border-color: transparent;

}

.btn-primary {
    background:var(--color-button);
    color:var(--color-white); 

}


.btn-black {
    background:#000000;
    color:var(--color-white); 

}

img {
    /*every image on this website is gonna be display block, i.e gonna occupy it's own line */
    display:block; 
    width:100%;
   object-fit:cover;
}



/* ======================== MEDIA QUERIES (TO MANAGE BTN SIZE FROM 1300PX) ======================*/

@media screen and (max-width:1400px) {


.btn {
    width:150px;  /* For text content this means that the content will not wrap at all even if it causes overflows  */
    height:45px;
    display:inline-block; 
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-white);
    padding:0.75rem 1.2rem; 
  
    cursor:pointer;
    border-radius: 10px;
    /*border:1px solid var(--color-bg-variant);*/
    transition: var(--transition);
    text-align: center;
    margin-top: 3rem;
    font-size: 0.7rem;
  }
  

}




/* ======================== MEDIA QUERIES (MEDIUM DEVICES) ======================*/

@media screen and (max-width:1024px) {
   .container {
     width: var(--container-width-md);
   }

  /* section {
    margin-top:6rem;
   }*/


}



/* ======================== MEDIA QUERIES (SMALL DEVICES) ======================*/

@media screen and (max-width:600px) {
    .container {
      width: var(--container-width-sm);
    }
 
    .section > h2 {
     margin-bottom:2rem;
    }


    h1 {
        font-size:2.2rem;
        font-weight:700;
        margin-bottom:1.5rem;
    }
    
    h2 {
        font-size:1.6rem;
        font-weight:700;
        margin-bottom:1.3rem;
    
    }
    
    h3 {
        font-size:1.3rem;
        font-weight:600;
        margin-bottom:1rem;
        margin-top: 2rem;
    }


    
 
 
 }









